.container {
    position: relative;
    width: 18.2rem;
    height: 4.8rem;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.selected {
    background-color: var(--white-color);
    color: var(--black-color);
    font-weight: 300;
    font-size: 1.3rem;
    box-shadow: var(--box-shadow);
    width: 100%;
    height: 100%;
    border-radius: 0.8rem;
    cursor: pointer;
}

.images {
    position: relative;
    width: 4rem;
    height: 4rem;

}

.image1 {
    width: 75%;
    height: 75%;
    position: absolute;
    top: 0;
    left: 0;
}

.image2 {
    position: absolute;
    width: 75%;
    height: 75%;
    top: 25%;
    left: 25%;
}

.arrow {
    width: 1.5rem;
    fill: var(--background-secondary);
}


.items {
    position: absolute;
    width: 18.2rem;
    top: 4.84rem;
    left: 0;
    background-color: var(--white-color);
    color: var(--black-color);
    font-weight: 300;
    font-size: 1.3rem;
    border-radius: 0.8rem;
    z-index: 2;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.item {
    height: 4.8rem;
    margin-right: 3.5rem;
    cursor: pointer;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

@media screen and (max-width: 450px) {
    .container {
        width: 17.1rem;
    }
}