.container {}

.row1 {
    display: flex;
    width: 100%;
    height: 66.4rem;
}

.col1 {
    width: 33.33%;
}
.col2 {
    width: 66.66%;
}
.marketContainer {
    border-left: var(--cell-border);
}