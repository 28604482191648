.openContainer {
    width: 27.05rem;
    padding: 2rem 3rem 6rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    color: var(--text-color-secondary);
}
.closeContainer{
    width: 10.3rem;
    display: flex;
    align-items: center;
    
}
.m1{
    flex: 1;
    text-align: center;
    cursor: pointer;
}
.im1{
    width: 3.9rem;
    height: 6.4rem;
}
.r1 {
    display: flex;
    justify-content: space-between;
    font-size: 1.3rem;
}

.btnHide {
    background-color: var(--background-secondary);
    height: 3rem;
    width: 5.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.2rem;
    cursor: pointer;
}

.btnHideTranslated {
    composes: btnHide;
    width: 9.5rem;
}

.growSpace {
    flex-grow: 1;
}

.item {
    display: flex;
    height: 4.4rem;
    width: 24rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    gap: 1rem;
    align-items: center;
    justify-content: left;
    font-size: 1.8rem;
    border-radius: 1.2rem;
    cursor: pointer;
    fill: var(--white-color);
}

.itemWithoutImg {
    padding-left: 5.5rem;
}

.itemImage{
    width: 2.5rem;
    height: 2.5rem;
}

.item:hover,
.selectedItem {
    font-weight: 600;
    background-color: var(--background-secondary);
}

.refContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 4rem;
}

.inputContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 0.57rem;
    font-weight: 500;
    width: 90%;
    height: 3.5rem;
    background-color: var(--background-secondary);
    border-radius: 0.8rem;
    padding-left: .9rem;
}

.copyBtn {
    width: 10%;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.copyIcon {
    display: flex;
    flex-grow: 1;
    height: 1.5rem;
    width: 1.5rem;
}

.refIcon {
    display: flex;
    flex-grow: 1;
    margin-right: 0.5rem;
    height: 2rem;
    width: 2rem;
    fill: var(--white-color);
}

.refLinkText {
    display: flex;
    flex-grow: 2;
    font-size: 1.2rem;
}

.certik {
    height: 5em;
    cursor: pointer;
}

.social {
    display: flex;
    gap: 0.8rem;
    justify-content: left;
    cursor: pointer;
}
.socialImage {
    width: 3.3rem;
    height: 3.3rem;
}