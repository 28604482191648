.red {
    color: var(--negative-growth);
}

.green {
    color: var(--positive-growth);
}

.row {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 10rem;
    padding-left: 9rem;
    padding-right: 5.8rem;
    font-size: 2rem;
    gap: 1.8rem;
    color: var(--white-color);
}

.row:nth-child(even) {
    background: var(--background-primary-main);
}

.row:nth-child(odd) {
    background: var(--background-primary-dark)
}

.col1 {
    width: 7.2rem;
    font-weight: 600;
    order: 1;
}

.short {
    color: var(--negative-growth);
}

.long {
    color:var(--positive-growth);
}

.col2 {
    width: 12rem;
    font-weight: 500;
    display: flex;
    justify-content: left;

    gap: 0.8rem;
    order: 3;
    flex-direction: column;
}

.col3 {
    width: 12rem;
    font-weight: 500;
    display: flex;
    justify-content: left;

    gap: 0.8rem;
    order: 3;
    flex-direction: column;
}

.col4 {
    flex-direction: column;
    gap: 0.8rem;
    width: 12rem;
    font-weight: 600;
    display: flex;
    justify-content: left;

    order: 7;
}

.col5 {
    width: 12rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.2rem;
    order: 9;
}

.col6 {
    width: 12rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.2rem;
    order: 11;
}

.col7 {
    width: 12rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.2rem;
    order: 13;
}

.col8 {
    width: 12rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.2rem;
    order: 15;
}

.col9 {
    width: 12rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.2rem;
    order: 17;
}

.col10 {
    width: 12rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.2rem;
    order: 19;
}

.col11 {
    width: 12rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.2rem;
    order: 21;
}

.col5_title {
    font-size: 1.3rem;
    font-weight: normal;
    color: var(--text-color-secondary);
}

.col5_value {
    font-weight: 700;
}

.closeBtn {
    width: 12rem;
    height: 3.5rem;
    background-color: var(--btn-bg-color);
    background-image: var(--btn-bg-image);
    color: var(--text-on-accent);
    font-size: 1.6rem;
    font-weight: 500;
    border-radius: 1.1rem;
    border: none;
    cursor: pointer;
    order: 21;
}

.hide {
    visibility: hidden;
}

.closeBtn:disabled {
    background-color: var(--btn-bg-dis);
    cursor: no-drop;
}


.tooltip {
    position: relative;
    border-bottom: 0.1rem dotted var(--accent-color);
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 20rem;
    background-color: var(--accent-color);
    color: var(--text-on-accent);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -10rem;
    font-size: 1.5rem;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--accent-color) transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

@media screen and (max-width: 450px) {
    .row {
        flex-wrap: wrap;
        height: auto;
        padding-left: 2.3rem;
        padding-right: 1rem;
        gap: 2.1rem;
        font-size: 1.55rem;
        row-gap: 1.5rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .col1 {
        width: 5.7rem;
    }

    .col2 {
        width: 9rem;
    }

    .col3 {
        width: 9rem;
    }

    .col4 {
        width: 9rem;
    }

    .closeBtn {
        order: 5;
        width: 8.4rem;
        height: 3.2rem;
        font-size: 1.1rem;
        border-radius: 1.57rem;
    }

    .col1,
    .col2,
    .col3,
    .col4,
    .col5,
    .col6,
    .col7,
    .col8,
    .col9,
    .col10,
    .col11 {
        width: 11.1rem;
    }
    .tooltip .tooltiptext {
        width: 14rem;
        margin-left: -4.5rem;
        font-size: 1.1rem;
        padding: 3px 0;
    }
}