.help {
    width: 1.2rem;
    height: 1.2rem;
    position: relative;
    font-size: 1rem;
    cursor: pointer;
}

.helpIcon {
    fill: var(--text-color-main);
    width: 100%;
    height: 100%;
}

.content {
    width: 28rem;
    border-radius: 0.7rem;
    border: 0.1rem solid var(--accent-color);
    background-color: var(--help-bg);
    color: var(--white-color);
    position: absolute;
    padding: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.1rem;
    font-weight: 400;
    border-top-left-radius: 0;
    z-index: 3;
    text-align: left;
}

.xmark {
    height: 0.9rem;
    fill: var(--accent-color);
    width: 1.1rem;
    align-self: end;
    cursor: pointer;
}

.short {
    top: 1.5rem;
    left: 1.5rem;
}

.long {
    top: 1.2rem;
    left: 0.6rem;
}

.left {
    border-top-right-radius: 0;
    border-top-left-radius: 0.7rem;
    left: unset;
    right: 0.6rem;
}