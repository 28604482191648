.container {
  height: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.growSpace {
  flex-grow: 1;
}

.actionBtn {
  height: 5.3rem;
  width: 16.1rem;
  color: var(--text-color-main);
  border-radius: 1.2rem;
  border: none;
  font-size: 1.8rem;
  font-weight: normal;
  margin-left: 0.65rem;
  margin-right: 0.65rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.3rem;
  background: transparent;
  border: 0;
}

.actionBtn:hover,
.actionBtnSelected {
  background-color: var(--background-secondary);
  font-weight: 600;
}

.actionBtnImg {
  fill: var(--text-color-main);
  width: 2.5rem;
  height: 2.5rem;
}

.actionBtnImg2 {
  fill: var(--text-color-main);
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  transform: translate(8rem);
  z-index: 1;
}

.balance {
  display: flex;
  flex-direction: column;
  margin-left: 0.65rem;
  margin-right: 0.65rem;
  color:  var(--white-color);
  gap: 0.5rem;
  position: relative;
}

.balanceR1 {
  font-size: 1.4rem;
  text-align: left;
  margin-left: 0.7rem;
}

.balanceR2 {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 1.9rem;
  font-weight: 600;
  width: 13.1rem;
  height: 3.1rem;
  background-color: var(--background-secondary);
  border-radius: 0.6rem;
  padding-left: 0.9rem;
}

.balanceR2tr {
  composes: balanceR2;
  width: 100%;
}

.balanceAnc {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15.6rem;
  height: 3.1rem;
  background: var(--background-green-gradient);
  border-radius: 0.7rem;
  border: solid 0.3px var(--green-border);
  font-size: 1.9rem;
  font-weight: 600;
  color: var(--white-color);
}

.separator1 {
  height: 5.7rem;
  width: 0.1rem;
  background-color: var(--text-color-main);
}

.separator2 {
  height: 5.7rem;
  width: 0.1rem;
  background-color: var(--text-color-main);
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.logo {
  width: 9.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.logo img {
  width: 3.2rem;
  height: 4.3rem;
}

.circleNumber {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  background-color: var(--green-border);
  color: var(--background-primary-main);
  font-size: 1.1rem;
  font-weight: 600;
  position: absolute;
  top: 1.3rem;
  right: -0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.moreAction {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 18rem;
}

.dropMenu {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 4rem;
  list-style: none;
  z-index: 10;
  width: 18rem;
  background-color: var(--background-secondary);
  gap: 3rem;
  padding: 2.5rem 1rem 1rem 1rem;
  text-align: center;
}

.dropMenuWithTopHeader {
  composes: dropMenu;
  top: 13rem;
}

.moreAction:hover .dropMenu {
  display: flex;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.dropMenu li a {
  width: 15rem;
  display: flex;
  text-decoration: none;
  font-weight: normal;
  margin-left: 0.75rem;
}

.moreActionword {
  font-size: 2rem;
  margin-right: 5rem;
}
.moreActionwordtr {
  composes: moreActionword;
  margin-right: 0;
}

@media screen and (max-width: 450px) {
  .container {
    height: 17rem;
    display: block;
  }

  .row1 {
    display: flex;
    align-items: center;
    height: 10.7rem;
  }

  .row2 {
    display: flex;
    align-items: center;
    background-color: var(--background-secondary);
    height: 6.3rem;
    color: var(--white-color);
  }

  .logo {
    width: 9rem;
  }

  .logo img {
    width: 4rem;
    height: 5.2rem;
  }

  .actionBtn {
    height: auto;
    width: 6.5rem;
    margin-left: 0;
    gap: 0.3rem;
    font-size: 1.2rem;
  }

  .actionBtnImg2 {
    width: 1rem;
    height: 1rem;
  }

  .anchor {
    flex: 1;
    height: 100%;
    background: var(--background-green-gradient);
    border: 0.05rem solid var(--green-border);
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
  }

  .anchorImg {
    width: 3.8rem;
    height: 3.8rem;
    background-color: var(--background-primary-main);
    padding: 0.4rem;
    border-radius: 50%;
  }

  .balance {
    margin-left: 0;
    margin-right: 0;
    padding-left: 1.9rem;
    width: 15.7rem;
  }

  .balanceR1 {
    font-size: 1.2rem;
  }

  .balanceR2 {
    font-size: 1.5rem;
    width: auto;
    height: auto;
  }
  .balanceR2tr {
    composes: balanceR2;
  }

  .separator {
    height: 100%;
    width: 0.05rem;
    background-color: var(--text-color-main);
  }

  .circleNumber {
    position: static;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
  }

  .anchor1 {
    font-size: 1.2rem;
  }

  .anchor2 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .bottomMenu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 9rem;
    background-color: var(--background-primary-dark);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .bottomMenuItem {
    color: var(--text-color-main);
    text-align: center;
    font-size: 1.1rem;
    width: 6.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    cursor: pointer;
    fill: var(--text-color-main);
  }

  .bottomMenuItemImg {
    width: 4rem;
    height: 4rem;
  }

  .bottomMenuItemSelected {
    color: var(--accent-color);
    fill: var(--accent-color);
  }
}

/* @media (max-width: 1600px) {
  .drop_open {
    transform: translate3d(90px, 130px, 0px) !important;
  }
}
@media (max-width: 1440px) {
  .drop_open {
    transform: translate3d(80px, 110px, 0px) !important;
  }
}
@media (max-width: 1366px) {
  .drop_open {
    transform: translate3d(70px, 110px, 0px) !important;
  }
} */

.drop_open a {
  display: block;
  padding: 1.25rem;
  text-align: center;
  border-bottom: 1px solid var(--grey-color);
}
.drop_open a:last-child {
  border-bottom: none;
}

.drop_open {
  display: none;
  position: absolute;
  /* transform: translate3d(105px, 150px, 0px) !important; */
  background: var(--background-secondary);
  font-size: 14px;
  /* padding: 5px 10px; */
  border-radius: 7px;
  width: 16rem;
  z-index: 10;
}

.buttonlang {
  position: relative;
}

.buttonlang button {
  background-color: transparent;
  border: 0;
  font-size: 1.6rem;
  color: var(--text-color-main);
  height: 5.6rem;
  width: 16rem;
  cursor: pointer;
  z-index: 10;
}
