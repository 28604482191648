.container {
  width: 100%;
  height: 8.8rem;
  background: var(--top-head-bg);
  opacity: 1;
  display: flex;
  padding-right: 2.716rem;
  padding-left: 49.2rem;
  align-items: center;
}

.close {
  width: 1.7rem;
  height: 1.7rem;
  align-self: center;
}

.logo {
  width: 13.3rem;
  height: 8.8rem;
}

.text1 {
  background: var(--background-secondary) 0 0% no-repeat padding-box;
  font-size: 1.4rem;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: center;
  border-radius: 0.7rem;
  display: flex;
  color: var(--white-color);
  margin-right: auto;
  width: 79.4rem;
  height: 5.4rem;
  margin-left: 1rem;
}

b {
  font-weight: 600;
  color: var(--white-color);
  font-size: 1.4rem;
}

.subText {
  margin-left: 0.25em;
}

@media screen and (max-width: 450px) {
  .container {
    height: 9.8rem;
    padding-right: 2.316rem;
    padding-left: 2rem;
    display: flex;
    align-items: center;
  }

  .text1 {
    font-size: 1.9rem;
    color: var(--white-color);
    display: flex;
    flex-direction: column;
    width: 79.4rem;
    height: 5.4rem;
    align-items: flex-start;
    background: transparent;
  }

  .logo {
    display: none;
  }

  b {
    font-size: 1.9rem;
  }

  .close {
    width: 2.6rem;
    height: 2.6rem;
  }
}
