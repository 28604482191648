.container {
    width: 100%;
    position: relative;
}

.s221 {
    display: flex;
    justify-content: left;
    gap: 0.8rem;
    align-items: center;
}

.num_description {
    color: var(--accent-color);
    font-size: 4.3rem;
    font-weight: 700;
}

.refLinkContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--accent-color);
    font-size: 1.7rem;
    font-weight: 600;
}

.s_description {
    color: var(--accent-color);
    font-size: 3rem;
    font-weight: 600;
}
.refLinkText {
    display: flex;
    align-items: center;
    height: 4.14rem;
}

.copyBtn {
    margin-right: 2.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.copyIcon {
    height: 2.5rem;
    width: 2.5rem;
}

.logo1 {
    width: 4.2rem;
    height: 4.2rem;
    border: 0.05rem solid var(--white-color);
    border-radius: 2.1rem;
    background-color: var(--background-primary-light);
    padding: 0.7rem;
}

.section2 {
    border-top: var(--cell-border);
    border-bottom: var(--cell-border);
    height: 23rem;
    display: flex;
}


.s1,
.s2,
.s3 {
    width: 33.333%;
    padding-left: 4.5rem;
    gap: 1.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--text-color-main);
    font-size: 2rem;
    background: var(--background-primary-gradient);
}

.s1,
.s2 {
    border-right: var(--cell-border);
}

.section3 {
    min-height: calc(100vh - 61rem);
    display: flex;
    background-color: var(--background-primary-light);
}

.s31 {
    width: 56%;
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
    color: var(--text-color-main);
    font-size: 1.6rem;
}

.s32 {
    width: 44%;
}

@media screen and (max-width: 450px) {
    .section2 {
        flex-direction: column;
        height: auto;
    }

    .s1 {
        width: 100%;
        border-top: var(--cell-border);
        border-bottom: var(--cell-border);
        border-right: none;
        order: 3;
        padding-top: 3.2rem;
        padding-bottom: 1.5rem;
    }

    .s2 {
        width: 100%;
        border-right: none;
        order: 2;
        padding-top: 3.2rem;
        padding-bottom: 1.5rem;
    }

    .s3 {
        width: 100%;
        order: 1;
        background: var(--background-green-gradient);
        border: 0.01rem solid var(--green-border);
        flex-direction: row;
        align-items: center;
        height: 7.8rem;
        justify-content: space-between;
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .section3 {
        flex-direction: column;
        background-color: var(--background-secondary);
        gap: 0.8rem;
    }

    .s31 {
        width: 100%;
        background-color: var(--background-primary-light);
        border: none;
    }

    .s32 {
        width: 100%;
        background-color: var(--background-primary-light);
    }

    .ex1 {
        display: none;
    }

    .s_description {
        font-size: 2.7rem;
        color: var(--white-color);
    }

    .s221 {
        color: var(--white-color);
    }

    .num_description {
        color: var(--white-color);
        font-size: 2.7rem;
        font-weight: 700;
    }

    .ref_link {
        font-size: 1.5rem;
        color: var(--white-color);
    }

    .copyIcon {
        height: 2rem;
        width: 2rem;
    }

}