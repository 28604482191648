.container {
    position: relative;
    height: 100%;
}

.box {
    color: var(--text-color-secondary);
    padding: 2.3rem 5rem 3rem 5rem;
}

.row1 {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0.5rem;
    font-size: 1.3rem;
}


.drop {}

.selectionRow {
    margin-top: 2.7rem;
    display: flex;
    justify-content: space-between;
    font-size: 2rem;
}

.selectionCol {
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    color: var(--white-color);
}

.unselected {
    font-weight: 300;
    color: var(--white-color);
}



.hide {
    visibility: hidden;
}

.currentRow {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 5rem;
}

.currentCol {
    display: inline-block;
}

.currentHead {
    font-size: 1.8rem;
    font-weight: 400;
}

.currentPrice {
    margin-top: 1.2rem;
    font-size: 2rem;
    font-weight: 700;
    color: var(--accent-color);
}

.currentUnit {
    font-size: 1.5rem;
    font-weight: 400;
    margin-left: 1.4rem;
    color: var(--accent-color);
}

.commit {
    font-size: 1.5rem;
    font-weight: 400;
    font-family: Poppins;
    color: var(--text-color-main);
    margin-top: 3.3rem;
}

.input {
    background-color: var(--white-color);
    box-shadow: var(--box-shadow);
    border-radius: 1.0982399999999999rem;
    margin-top: 1.2rem;
    border: none;
    width: 100%;
    height: 4.2rem;
    padding-left: 1rem;
    padding-right: 9rem;
}

.input:focus {
    outline-width: 0;
}

.profitLossinput:focus {
    outline-width: 0;
}

.liquidation {
    margin-top: 1.92rem;
}

.totelCommit {
    margin-top: 1rem;
}

.maxButton {
    position: absolute;
    width: 7rem;
    height: 3rem;
    background-color: var(--accent-color);
    color: var(--text-on-accent);
    border-radius: 1.0982399999999999rem;
    box-shadow: var(--box-shadow);
    font-size: 1.79904rem;
    font-weight: 400;
    border: none;
    cursor: pointer;
    top: 1.8rem;
    right: 0.8rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.leverage {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--white-color);
    margin-top: 3.3rem;
}

.rangeparent {
    margin-top: 2.4rem;
}

.range {
    background: var(--open-position-element-bg);
    width: 46.199040000000004rem;
    height: 0.78336rem;
    border: none;
    cursor: pointer;
}

/*Chrome*/

input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    border-radius: 1.92rem;
    background: var(--open-position-element-bg);
}

input[type='range']::-webkit-slider-runnable-track {
    width: 46.199040000000004rem;
    height: 0.78336rem;
    border-radius: 1.92rem;
    background: var(--open-position-element-bg);
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 1.92rem;
    width: 1.92rem;
    border-radius: 50%;
    background: var(--white-color);
    margin-top: -0.96rem;
    box-shadow: var(--box-shadow);
}


/** FF*/
input[type="range"]::-moz-range-progress {
    background: var(--open-position-element-bg);
}

input[type="range"]::-moz-range-track {
    background: var(--open-position-element-bg);
}

/* IE*/
input[type="range"]::-ms-fill-lower {
    background: var(--open-position-element-bg);
}

input[type="range"]::-ms-fill-upper {
    background: var(--open-position-element-bg);
}

.rangeButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 1.3rem;
    width: 100%;
}

.rangeButton {
    border: none;
    background-color: var(--white-color);
    color: var(--black-color);
    font-size: 1.3rem;
    font-weight: 300;
    font-family: Avenir;
    width: 4.2rem;
    height: 2.8rem;
    border-radius: 1.4rem;
    padding: inherit;
}

.checkBoxparent {
    margin-top: 3.0316799999999997rem;
    display: flex;
    align-items: center;
}

.checkBox {
    width: 2.6995199999999997rem;
    height: 2.6995199999999997rem;
    background-color: var(--accent-color);
    color: var(--text-on-accent);
    font-size: 1.92rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.384rem;
    cursor: pointer;

}

.checkBoxLabel {
    font-size: 1.5rem;
    font-family: Poppins;
    font-weight: 400;
    color: var(--accent-color);
    margin-left: 1.5rem;
}

.profitLoss {
    display: flex;
    justify-content: space-between;
    margin-top: 1.76688rem;

}

.profitLossHeader {
    font-size: 2rem;
    font-weight: 400;
    color: var(--white-color);
}

.plCol {
    display: inline-block;
    text-align: left;
}

.profitLossinput {
    background-color: var(--white-color);
    box-shadow: var(--box-shadow);
    border-radius: 0.89856rem;
    margin-top: 1.5rem;
    border: none;
    width: 21.791999999999998rem;
    height: 4.896rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.error1,
.error2 {
    font-size: 1.49952rem;
    font-weight: 400;
    color: var(--error-color);
    margin-top: 1rem;
}

.error3 {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--error-color);
    margin-top: 0.5rem;
}

.entryRow {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
}

.entryText {
    font-size: 2.1rem;
    font-weight: 400;
    color: var(--white-color);

}

.entryNum {
    font-size: 2.3rem;
    font-weight: 500;
    color: var(--white-color);
}

.loading {
    height: 4rem;
}

.btnContent {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

.openButtonParrent {
    margin-top: 2.7993599999999996rem;
    text-align: center;

}

.hide2 {
    display: none;
}


.inpContainer {
    position: relative;
}

.tabContainer {
    display: flex;
    position: relative;
    width: 28rem;
    padding-left: 2.7rem;
    padding-right: 2.7rem;
    justify-content: space-between;
    transition: all 2000ms;
}

.hr {
    border: none;
    border-top: 0.39936rem solid var(--accent-color);
    width: 11rem;
    top: 2.1rem;
    position: absolute;
    left: 0;
    transition: all 600ms;
}

.shortTab {
    left: 16rem;
}

.tabtitle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

@media screen and (max-width: 450px) {
    .box {
        padding: 2.3rem 2.5rem 3rem 2.5rem;
    }

    .profitLossinput {
        width: 15.7rem;
    }
}